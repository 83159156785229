<template>
  <div class="about">
    <h1>Completed Masks</h1>
    <button @click="getFilelist" class="dlink">Refresh</button>
    <!-- <button @click="putFile">put</button> -->
    <div class="dfiles">
      <div v-for="(result, index) in locresult" :key="index">
        <div v-if="result.key.split('.').pop() == 'pdf'">
          <div class="dfile">{{ result.key.split("/").pop() }}</div>

          <div class="dbutton">
            <a :href="dlinks[index]" class="dlink">Download</a>
            <!-- <button @click="getFile(result.key)" class="dlink">get</button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <p>results: {{ locresult }}</p> -->
  </div>
  <!-- end about -->
</template>
<script>
import { Storage } from "aws-amplify";
// eslint-disable-next-line no-unused-vars
import path from "path";
import store from "@/store/index.js"; // top level store object

export default {
  data() {
    return {
      locresult: "",
      dlinks: [],
      lockey: path.join(
        this.$store.state.userinfo.co.replace(/[^a-z0-9.]/gi, "_"),
        this.$store.state.userinfo.site.replace(/[^a-z0-9.]/gi, "_"),
        this.$store.state.userinfo.uemail.replace(/[^a-z0-9.]/gi, "_")
      ),
    };
  },
  store,

  methods: {
    getFilelist() {
      Storage.list(this.lockey, { level: "public" }) // listing based on userinfo
        .then((result) => (this.locresult = result))
        .then(() => this.dlfile())
        .catch((err) => console.log(err));
    },
    async putFile() {
      // eslint-disable-next-line no-unused-vars
      const result = await Storage.put("test3.txt", "Hello there 2", {
        level: "public",
        contentType: "text/plain",
      });
    },
    async getFile(payload) {
      alert(payload);
      // Get presingned url for s3 object
      // eslint-disable-next-line no-unused-vars
      const result = await Storage.get(payload, {
        level: "public", // defaults to `public`
        // identityId?: string, // id of another user, if `level: protected`
        download: "true", // defaults to false
        expires: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes)
        contentType: "app/pdf", // set return content type, eg "text/html"
      });
      alert(payload.split("/").pop()); // get filename
    },
    async dlfile() {
      for (const myobj of this.locresult) {
        const signedUrl = await Storage.get(myobj.key, {
          level: "public", // defaults to `public`
          // identityId?: string, // id of another user, if `level: protected`
          download: "false", // defaults to false
          expires: 86400, // validity of the URL, in seconds. defaults to 900 (15 minutes)
          contentType: "app/pdf", // set return content type, eg "text/html"
        });
        this.dlinks.push(signedUrl);
      }
    },
  },
  beforeMount() {
    this.getFilelist();
  },
};
</script>
<style scoped>
.dlink {
  display: inline-block;
  /* min-width: 10rem; */
  background-color: #469;
  min-width: 6rem;
  color: white;
  padding: 0.25rem;
  text-decoration: none;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
}
.dfiles {
  width: 50rem;
  background-color: #ddd;
  padding-top: 1.5rem;
  margin: auto;
  border-radius: 0.5rem;
  text-align: left;
}

.dfile {
  display: inline-block;
  padding-left: 2rem;
  width: 35rem;
}
.dbutton {
  display: inline-block;
  /* padding: 0.25rem; */
  text-align: center;
}
</style>
